import React from "react"
import { nanoid } from "nanoid"
import Footer from "./Footer"
import Header from "./Header"

const Products = () => {
    const contentList = [
        {
            id: nanoid(),
            imgSrc: process.env.PUBLIC_URL + "/img/product/cart.png",
            title: 'Shopping Cart',
            description: <>
                E-Commerce Platform that helps you to Sell Online in Four steps
                <br />
                <ol>
                    <li>Customize the design of your shop</li>
                    <li>Add your products to the shop</li>
                    <li>List your products with the best photos, price and descriptions</li>
                    <li>Start selling your products online.</li>
                </ol>
            </>,
            containerBackgroundClass: 'shop',
            link: 'http://myshop.mindzcube.com/'
        },
        {
            id: nanoid(),
            imgSrc: process.env.PUBLIC_URL + "/img/product/farm-app.svg",
            title: 'Farm App',
            description: <>It help farmers to capture, analyse and control their financial data and manage their farming and household expenses better. It also maintain customers and their sales.<br /><br />This first-of-its-kind personal utility app made specifically for farmers provides a free, safe and secure service in their native language (Tamil)</>,
            containerBackgroundClass: 'farm',
            link: 'http://myfarm.mindzcube.com/'
        },
        {
            id: nanoid(),
            imgSrc: process.env.PUBLIC_URL + "/img/product/day-book.png",
            title: 'Day Book',
            description: <>Income and Expense management application for small and medium businesses. it is easy to use, The customer can easy maintain their income and expaense of their business. It also support muliple branches and each branch maintain their own user and it's records. It also provides various reports.</>,
            containerBackgroundClass: 'day-book',
            link: 'http://daybook.mindzcube.com/'
        },
    ]
    return <div className="fixed-top-bar">
        <Header />
        {contentList.map(content => {
            return <div className={`product-container ${content.containerBackgroundClass}`} key={content.id}>
                <div className="left-product-container">
                    <img src={content.imgSrc} alt={content.title} className="fade-slide-right" />
                </div>
                <div className="right-product-container">
                    <div className="fade-pop-up">
                        <h1 className="product-heading">{content.title}</h1>
                        <div className="product-description">{content.description}</div>
                        {content.link !== '' && <>
                            <br />
                            <a href={content.link} target="_blank" rel="noreferrer" className="btn btn-lg btn-danger">View Now</a>
                        </>}
                    </div>
                </div>
            </div>
        })}
        <Footer />
    </div>
}

export default Products