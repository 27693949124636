import React from "react"
import { nanoid } from "nanoid"
import Footer from "./Footer"
import Header from "./Header"

const Portfolio = () => {
    const contentList = [
        {
            id: nanoid(),
            src: process.env.PUBLIC_URL + '/img/works/full/sscoe.jpg',
            title: 'Sri Sarada College of Education',
            alt: 'Sri Sarada College of Education was established in the year 1968-69 by the dynamic effort of Mrs. Seetha Lakshmi Ramaswamy(Yatiswari Sarada Priya) under the presidentship of Srimath Swami Chidbhavananda of Sri Ramakrishna Tapovanam, Tirupparaithurai. With the aim of moulding the future teachers with intellectual, moral and spiritual values, Sri Sarada College Education Trust, Salem propels the institution in the promising path. <a href="https://sscoe.ac.in/" alt="" target="_blank" rel="noreferrer">Visit</a>'
        },
        {
            id: nanoid(),
            src: process.env.PUBLIC_URL + '/img/works/full/alsabah.jpg',
            title: 'Al Sabah Construction W.L.L.',
            alt: 'Al Sabah Construction W.L.L. is a construction pioneer in the kingdom since 1985 in the service industry has been a remarkable journey from 80\'s and its high standards set a demand in the industry for its precisional work. <a href="http://alsabahconstruction.com/" alt="" target="_blank" rel="noreferrer">Visit</a>'
        },
        {
            id: nanoid(),
            src: process.env.PUBLIC_URL + '/img/works/full/bonsecours.jpg',
            title: 'Bon Secours College for Women',
            alt: 'Bon Secours College for women was instituted by the Franciscan sisters of our Lady of Bon Secours, a Religious Congregation dedicated to uplift the downtrodden, especially women. <br /><br /> It is very interesting to note that a tempest sowed the seed for the founding of the Congregation. This storm had served as a source of inspiration for Rev.Fr.Courdex S.J. to launch this noble venture in the year 1775 A.D. Then it took shape as a religious congregation in 1858 and was later re founded in the Arch-Diocese of Madras-Mylapore in 1894 with the lofty idea of imparting education to the native women.'
        },
        {
            id: nanoid(),
            src: process.env.PUBLIC_URL + '/img/works/full/iwwgroups.jpg',
            title: 'IWW Group of Companies',
            alt: 'With lot of hope and Planning Strategy to deliver a high Quality Performance on the Field of Energy Services We Started to Run in the Year 2012. And Now we are Standing as a Pillar with Successful Project Completion History. Completed Projects with MODEC(Petronas), WASCO Engineering Indonesia, Tripatra(EXXON MOBIL CEPU LIMTIED), Swiber, Indonesian Coast Guard Ship, PunjLlyod, Drydocks World, Macdermott,Siemens. We are Proud to say about Our Yard Facilities; We have 32 Hectares of Yard with 52m Waterfront, Copper Slag Process Plant, Blasting & Painting Shop Open Fabrication Area, Jetty and Launch Way, Office and Client Office, 2 Main Stores, Equipment Storage Area, Machine Shop(62mx 126m), Piping Workshop(25mx108m), Steel Workshop(2x20mx108m) and Open Storage Area. <a href="https://iwwgroups.com/" alt="" target="_blank" rel="noreferrer">Visit</a>'
        },
        {
            id: nanoid(),
            src: process.env.PUBLIC_URL + '/img/works/full/mks-batteries.jpg',
            title: 'MKS Batteries',
            alt: 'We are authorized dealers of Exide, Amaron, Luminous, Micro tek batteries and inverters. We also look after the sales & service of the above mentioned batteries.We have seperate divisions for every departments like Admin, Purchase, Production, Testing, Quality Checking, Delivery & Servicing.We have 5 two wheelers & 2 four wheelers for quick & fast delivery to the customers. <a href="http://mksdistributers.com/" alt="" target="_blank" rel="noreferrer">Visit</a>'
        },
        {
            id: nanoid(),
            src: process.env.PUBLIC_URL + '/img/works/full/sagar-silks.jpg',
            title: 'Sagar Silk House and Readymades',
            alt: '<a href="https://sagarsilks.com/" alt="" target="_blank" rel="noreferrer">Visit</a>'
        },
        {
            id: nanoid(),
            src: process.env.PUBLIC_URL + '/img/works/full/shoppers-like.jpg',
            title: 'Shoppers Like',
            alt: '<a href="http://www.shopperslike.com//" alt="" target="_blank" rel="noreferrer">Visit</a>'
        },
    ]

    return <div className="fixed-top-bar">
        <Header />
        <div className="container">
            <h3 style={{ marginTop: "20px" }}>Portfolio</h3>
            <p>Mindzcube continues to work on the projects which are being assigned through various national and international sources. Some of the key developments are being for our following few-listed clientele:</p>
            <h3 style={{ marginTop: "20px" }}>Some of recent <strong>works</strong></h3>
            <section id="projects">
                <ul id="thumbs" className="portfolio">
                    {contentList.map((content, index) => {
                        return <li className="item-thumbs span5" data-id={`id-${index}`} data-type="illustrator" key={content.id}>
                            <a className="hover-wrap fancybox" data-fancybox-group="gallery" title={content.title} href={content.src} style={{ display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none" }}>
                                <span className="overlay-img"></span>
                                <div style={{ color: "#fff", zIndex: "1", fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>{content.title}</div>
                            </a>
                            <img src={content.src} alt={content.alt} style={{ width: '100%', height: '100%' }} />
                        </li>
                    })}
                </ul>
            </section>
        </div>
        <div className="container">
            <p>Mindzcube continues to strive in building capabilities of local resource pool and make them deliver products and services which earn accolades from diverse group of clients sitting across the globe.</p>
        </div>
        <br />
        <Footer />
    </div>
}

export default Portfolio