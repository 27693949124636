import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ContactUs from './component/ContactUs'
import Home from './component/Home'
import Products from './component/Products'
import Portfolio from './component/Portfolio'
import AboutUs from './component/AboutUs'

const App = () => {
    return <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/products" component={Products} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/about-us" component={AboutUs} />
        </Switch>
    </BrowserRouter>
}

export default App