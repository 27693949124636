import Canvas from 'canvas'

export const getCaptchaValue = () => {
    return Math.floor(Math.random() * 9000) + 999
}

export const randomColor = () => {
    const r = Math.floor(Math.random() * 256)
    const g = Math.floor(Math.random() * 256)
    const b = Math.floor(Math.random() * 256)
    return 'rgb(' + r + ',' + g + ',' + b + ')'
}

export const getCaptchaImage = (value) => {
    const width = 350
    const height = 150
    const canvas = Canvas.createCanvas(width, height, 'svg')
    const context = canvas.getContext('2d')
    context.fillStyle = randomColor()
    context.fillRect(0, 0, width, height)

    for (let i = 0; i < width; i++) {
        for (let j = 0; j < height; j++) {
            context.fillStyle = randomColor()
            context.fillRect(i, j, 3, 3);
        }
    }

    context.font = '90px Arial'
    context.fillStyle = '#000'
    context.fillText(value.toString(), 75, 105)

    for (let i = 0; i < 50; i++) {
        context.strokeStyle = randomColor()
        context.beginPath()
        const x = Math.random() * width
        const y = Math.random() * width
        context.moveTo(x, 0);
        context.lineTo(y, height);
        context.stroke();
    }

    for (let i = 0; i < 50; i++) {
        context.strokeStyle = randomColor()
        context.beginPath()
        const x = Math.random() * height
        const y = Math.random() * height
        context.moveTo(0, x);
        context.lineTo(width, y);
        context.stroke();
    }

    return canvas.toDataURL('image/jpeg')
}