import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
    return <footer>
        <div id="sub-footer">
            <div className="container">
                <div className="row">
                    <div className="span6">
                        <div className="copyright">
                            <p>
                                <span>&copy; <a href="/">mindzcube</a> - All right reserved.</span>
                            </p>
                            <div className="credits">Designed by <a href="https://bootstrapmade.com/" target="_blank" rel="noreferrer" >BootstrapMade</a></div>
                            <div className="credits">Images used from <a href="https://www.freepik.com/" target="_blank" rel="noreferrer" >Freepik</a></div>
                        </div>
                    </div>
                    <div className="span6">
                        <ul className="social-network">
                            <li><a href="https://www.facebook.com/mindzcube" target="_blank" rel="noreferrer" data-placement="bottom" title="Facebook"><i className="icon-facebook icon-square"></i></a></li>
                            <li><a href="https://wa.me/918270583983" target="_blank" rel="noreferrer" data-placement="bottom" title="Whatsapp"><i className="fa fa-whatsapp icon-square"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="span6">
                        <div className="footer-address">
                            <p>#920A, First Floor, Baskarapuram,</p>
                            <p>Near to New Bus Stand,</p>
                            <p>Thanjavur - 613005</p>
                            <br />
                            <p>
                                <strong>

                                    <a href="tel:+918270583983">
                                        <i className="fa fa-phone" aria-hidden="true" style={{ paddingRight: '1rem' }} />
                                        +91 4362 465 632
                                    </a>
                                    <br />
                                    <a href="tel:+918270583983">
                                        <i className="fa fa-whatsapp" aria-hidden="true" style={{ paddingRight: '1rem' }} />
                                        +91 82705 83983
                                    </a>
                                    <br />
                                    <a href="mailto:info@mindzcube.com">
                                        <i className="fa fa-envelope" aria-hidden="true" style={{ paddingRight: '1rem' }} />
                                        info@mindzcube.com
                                    </a>
                                </strong>
                            </p>
                            <br />
                            <p>
                                {/* <a href="https://goo.gl/maps/2YAPK2XUjutKBDcJ8" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-map-marker" aria-hidden="true" style={{ paddingRight: '1rem' }} />
                                    Map Location
                                </a> */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Link to="#" className="scrollup"><i className="icon-chevron-up icon-square icon-32 active"></i></Link>
    </footer>
}

export default Footer