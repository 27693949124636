import React from "react"
import { Link } from "react-router-dom"

const Slide = () => {
    return <section id="featured">
        <div id="nivo-slider">
            <div className="nivo-slider">
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-9.jpg"} alt="" title="#caption-9" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-1.jpg"} alt="" title="#caption-1" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-2.jpg"} alt="" title="#caption-2" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-3.jpg"} alt="" title="#caption-3" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-4.jpg"} alt="" title="#caption-4" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-5.jpg"} alt="" title="#caption-5" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-6.jpg"} alt="" title="#caption-6" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-7.jpg"} alt="" title="#caption-7" />
                <img src={process.env.PUBLIC_URL + "/img/slides/nivo/bg-8.jpg"} alt="" title="#caption-8" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="span12">
                    <div className="nivo-caption" id="caption-9">
                            <div>
                                <h2><strong>An Agri-Based Software Product Development Company</strong></h2>
                                <p>
                                FPO Automation - Poultry Management Automation - Poultry & Livestock Management Automation - DairyFarm Management - Agri-Focused Multi-Vendor 
                                E-Marketplace Portal - Agri DayBook Multi-Branch Application - Digital Social Media Marketing Management For Agri.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-1">
                            <div>
                                <h2><strong>Application Development</strong></h2>
                                <p>
                                    We create ever engaging apps which showcase the dynamics of your business propositions with the ease of use.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-2">
                            <div>
                                <h2><strong>Domain Registration, Hosting & Maintenance</strong></h2>
                                <p>
                                    With us you can get the end-to-end solution right from registering the domain to micro-managing the site.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-3">
                            <div>
                                <h2><strong>E-Commerce</strong></h2>
                                <p>
                                    Let us know your customers and we will build the products to cater your customers through our multi-way e-commerce solution.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-4">
                            <div>
                                <h2><strong>Responsive & Reactive Web Development</strong></h2>
                                <p>
                                    As you announce your presence across the devices to your crowd, we will back you up with the sites that are responsive, reactive and engaging.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-5">
                            <div>
                                <h2><strong>UI/UX & Graphics Design</strong></h2>
                                <p>
                                    For captivating the customers, all boils down to the way you give the experience and interface with the them. We understand that well and give you the design solutions that captures the imagination.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-6">
                            <div>
                                <h2><strong>UI/UX & Graphics Design</strong></h2>
                                <p>
                                    For captivating the customers, all boils down to the way you give the experience and interface with the them. We understand that well and give you the design solutions that captures the imagination.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-7">
                            <div>
                                <h2><strong>Data Analysis</strong></h2>
                                <p>
                                    Data is the new gold which need to be mined to bring out insights and foresights about your current and future customers. This hand we have the tools & techniques which gives exactly that, not more no less.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                        <div className="nivo-caption" id="caption-8">
                            <div>
                                <h2><strong>Social Media Marketing</strong></h2>
                                <p>
                                    Social media is an ever evolving dynamic world where perceptions change every minute, this is “the” marketplace to showcase your products and we know how to do that.
                                </p>
                                <Link className="btn btn-theme" to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Slide