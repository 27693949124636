import React from "react"
import { Link, useLocation } from "react-router-dom"
import { nanoid } from "nanoid"

const Header = () => {
    const { pathname } = useLocation()
    const navContent = [
        {
            id: nanoid(),
            name: 'Home',
            link: '/'
        },
        {
            id: nanoid(),
            name: 'About Us',
            link: '/about-us'
        },
        {
            id: nanoid(),
            name: 'Products',
            link: '/products'
        },
        {
            id: nanoid(),
            name: 'Portfolio',
            link: '/portfolio'
        },
        {
            id: nanoid(),
            name: 'Contact Us',
            link: '/contact-us'
        },
    ]

    return <header style={{ top: '0', left: '0' }}>
        <div className="container ">
            <div className="row" style={{ marginBottom: '5px' }}>
            </div>
            <div className="row" style={{ marginBottom: '5px' }}>
                <div className="span4">
                    <div className="logo">
                        <a href="/"><img src={process.env.PUBLIC_URL + "/img/logo.png"} alt="" className="logo" /></a>
                    </div>
                </div>
                <div className="span8">
                    <div className="navbar navbar-static-top">
                        <div className="navigation">
                            <nav>
                                <ul className="nav topnav">
                                    {navContent.map(nav => {
                                        return <li className={`${nav.link === pathname ? 'active' : ''}`} key={nav.id}>
                                            {nav.link === '/' ? <a href={nav.link}>{nav.name}</a> :
                                                <Link to={nav.link}>{nav.name}</Link>}
                                        </li>
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
}

export default Header