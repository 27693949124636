import React, { useState } from "react"
import { nanoid } from "nanoid"
import Footer from "./Footer"
import Header from "./Header"

const AboutUs = () => {
    const contentList = [
        {
            id: nanoid(),
            title: 'Modern Web Designing, Serverless Computing, Mobile & Cloud App Development',
            src: process.env.PUBLIC_URL + '/img/about/web-design.png'
        },
        {
            id: nanoid(),
            title: 'Data Science, ML, DL & AI',
            src: process.env.PUBLIC_URL + '/img/about/data-science.jpg'
        },
        {
            id: nanoid(),
            title: 'Digital, Social Media Development & Management',
            src: process.env.PUBLIC_URL + '/img/about/social-media.png'
        },
        {
            id: nanoid(),
            title: 'Web Hosting & Cloud Provisioning',
            src: process.env.PUBLIC_URL + '/img/about/web-hosting.jpg'
        }
    ]

    return <div className="fixed-top-bar">
        <Header />

        <div className="container">
            <h3 style={{ marginTop: '20px' }}>Who <strong>we are?</strong></h3>
            <p>Mindzcube is a next-generation global technology company that helps enterprises reimagine their businesses for the digital age.</p>
            <p>We brings strong skills and modern ideas to solve customer challenges. We use proven knowledge to make recommendations and provide expert guidance to our customers.</p>
            <h3 style={{ marginTop: '20px' }}>Our <strong>Services</strong></h3>
            {contentList.map(content => <ServicesList content={content} key={content.id} />)}
        </div>
        <Footer />
    </div>
}

const ServicesList = ({ content }) => {
    const [imgLoading, setImgLoading] = useState(true)

    const img = new Image()
    img.addEventListener('load', () => {
        setImgLoading(false)
    })
    img.src = content.src

    return <>
        <div className="service-container" >
            <div className="left-service">
                <p className="service-title">{content.title}</p>
            </div>
            <div className="right-service">
                {imgLoading && <div className="service-img-loader" />}
                <img src={content.src} alt={content.title} className="service-img" style={{ maxHeight: '300px', display: `${imgLoading ? 'none' : 'block'}` }} />
            </div>
        </div>
        <hr />
    </>
}

export default AboutUs