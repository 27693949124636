import React from "react"
import { Link } from "react-router-dom"

const Services = () => {
    return <section id="content">
        <div className="container">
            <div className="row">
                <div className="span12">
                    <div className="row">
                        <div className="span3">
                            <div className="box aligncenter">
                                <div className="aligncenter icon">
                                    <i className="icon-tablet icon-circled icon-64 active"></i>
                                </div>
                                <div className="text">
                                    <h6>Application Development</h6>
                                    <p>
                                        We create ever engaging apps which showcase the dynamics of your business propositions with the ease of use.
                                    </p>
                                </div>
                                <br /><br /><br /><br />
                                <div style={{ textAlign: "center" }}>
                                    <Link className="btn btn-large btn-theme btn-rounded shake-effect" to="/contact-us">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="span3">
                            <div className="box aligncenter">
                                <div className="aligncenter icon">
                                    <i className="icon-desktop icon-circled icon-64 active"></i>
                                </div>
                                <div className="text">
                                    <h6>UI/UX & Graphics Design</h6>
                                    <p>
                                        For captivating the customers, all boils down to the way you give the experience and interface with the them. We understand that well and give you the design solutions that captures the imagination.
                                    </p>
                                </div>
                                <br /><br />
                                <div style={{ textAlign: "center" }}>
                                    <Link className="btn btn-large btn-theme btn-rounded shake-effect" to="/contact-us">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="span3">
                            <div className="box aligncenter">
                                <div className="aligncenter icon">
                                    <i className="icon-globe icon-circled icon-64 active"></i>
                                </div>
                                <div className="text">
                                    <h6>Web Devlopment</h6>
                                    <p>
                                        As you announce your presence across the devices to your crowd, we will back you up with the sites that are responsive, reactive and engaging.
                                    </p>
                                </div>
                                <br /><br /><br />
                                <div style={{ textAlign: "center" }}>
                                    <Link className="btn btn-large btn-theme btn-rounded shake-effect" to="/contact-us">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="span3">
                            <div className="box aligncenter">
                                <div className="aligncenter icon">
                                    <i className="icon-bar-chart icon-circled icon-64 active"></i>
                                </div>
                                <div className="text">
                                    <h6>Data Analysis</h6>
                                    <p>
                                        Data is the new gold which need to be mined to bring out insights and foresights about your current and future customers. This hand we have the tools & techniques which gives exactly that, not more no less.
                                    </p>
                                </div>
                                <br /><br />
                                <div style={{ textAlign: "center" }}>
                                    <Link className="btn btn-large btn-theme btn-rounded shake-effect" to="/contact-us">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Services