import React from "react"
import Footer from "./Footer"
import Header from "./Header"
import Services from "./Services"
import Slide from "./Slide"

const Home = () => {
    return <div className="fixed-top-bar">
        <Header />
        <Slide />
        <section className="callaction">
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <div className="big-cta">
                            <div className="cta-text">
                                <h3> We Develop Your<span className="highlight"><strong> Apps</strong></span> & Make Your <span className="highlight"><strong>Business</strong></span> Better Than Others.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Services />
        <Footer />
    </div>
}

export default Home