import React, { useEffect, useState } from 'react'
import { api } from '../include/api'
import { getCaptchaImage, getCaptchaValue } from '../include/functions'
import Footer from './Footer'
import Header from './Header'

const ContactUs = () => {
    const [captchaValue, setCaptchaValue] = useState(getCaptchaValue())
    const [captchaImage, setCaptchaImage] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [numberError, setNumberError] = useState('')
    const [subjectError, setSubjectError] = useState('')
    const [messageError, setMessageError] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertClass, setAlertClass] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [enteredCaptchaValue, setEnteredCaptchaValue] = useState('')
    const [enteredCaptchaValueError, setEnteredCaptchaValueError] = useState('')

    const handleName = (value) => {
        setNameError('')
        setName(value)
    }

    const handleEmail = (value) => {
        setEmailError('')
        setEmail(value)
    }

    const handleNumber = (value) => {
        setNumberError('')
        setNumber(value)
    }

    const handleSubject = (value) => {
        setSubjectError('')
        setSubject(value)
    }

    const handleMessage = (value) => {
        setMessageError('')
        setMessage(value)
    }

    const handleEnteredCaptchaValue = (value) => {
        setEnteredCaptchaValueError('')
        setEnteredCaptchaValue(value)
    }

    const handleSubmit = () => {
        let error = false
        const data = {
            name: String(name).trim(),
            email: String(email).trim().toLowerCase(),
            number: String(number).trim(),
            subject: String(subject).trim(),
            message: String(message).trim(),
        }

        const setError = (setFunc, errorText) => {
            setFunc(errorText)
            error = true
        }

        if (data.name === '') {
            setError(setNameError, 'Name required')
        } else if (data.name.length < 4) {
            setError(setNameError, 'Please enter at least 4 chars')
        }

        if (data.email === '') {
            setError(setEmailError, 'Email required')
        } else if (!data.email.match(/^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i)) {
            setError(setEmailError, 'Please enter a valid email')
        }

        if (data.number !== '' && !data.number.match(/^[6-9]{1}[0-9]{9}$/)) {
            setError(setNumberError, 'Please enter a valid number')
        }

        if (data.subject === '') {
            setError(setSubjectError, 'Subject required')
        } else if (data.subject.length < 8) {
            setError(setSubjectError, 'Please enter at least 8 chars of subject')
        }

        if (data.message === '') {
            setError(setMessageError, 'Please write something for us')
        }

        if (enteredCaptchaValue === '') {
            setError(setEnteredCaptchaValueError, 'Enter captcha')
        } else if (Number(captchaValue) !== Number(enteredCaptchaValue)) {
            setError(setEnteredCaptchaValueError, 'Invalid captcha')
        }

        if (!error) {
            api.post('/mail/', data).then(response => {
                if (response.status === 200) {
                    setAlertMessage(response.data.message)
                    setAlertClass('success')
                }
            }).catch(error => {
                error.response ? setAlertMessage(error.response.data.message) : setAlertMessage(error.message)
                setAlertClass('danger')
            }).finally(() => {
                setShowAlert(true)
            })
        }
    }

    const refreshCaptcha = () => {
        setCaptchaValue(getCaptchaValue())
    }

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false)
            }, 10000)
        }
    }, [showAlert])

    useEffect(() => {
        setCaptchaImage(getCaptchaImage(captchaValue))
    }, [captchaValue])

    return <div className="fixed-top-bar">
        <Header />
        <section className="callaction">
            <div className="container">
                <div className="row">
                    <div className="span12">
                        {showAlert && <div className={`alert alert-${alertClass}`}>
                            <span>{alertMessage}</span>
                            <span className="email-alert-close" onClick={() => setShowAlert(false)}>&times;</span>
                        </div>}
                        <h4>Get in touch with us by filling <strong>contact form below</strong></h4>
                        <form action="#" method="post" className="contactForm" onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                <div className="span4 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={e => handleName(e.target.value)} value={name} />
                                    <div className="validation" style={{ display: `${nameError === '' ? 'none' : 'block'}` }}>{nameError}</div>
                                </div>
                                <div className="span4 form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" onChange={e => handleEmail(e.target.value)} value={email} />
                                    <div className="validation" style={{ display: `${emailError === '' ? 'none' : 'block'}` }}>{emailError}</div>
                                </div>
                                <div className="span4 form-group">
                                    <input type="text" className="form-control" name="number" id="number" placeholder="Contact Number" onChange={e => handleNumber(e.target.value)} value={number} />
                                    <div className="validation" style={{ display: `${numberError === '' ? 'none' : 'block'}` }}>{numberError}</div>
                                </div>
                                <div className="span12 form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={e => handleSubject(e.target.value)} value={subject} />
                                    <div className="validation" style={{ display: `${subjectError === '' ? 'none' : 'block'}` }}>{subjectError}</div>
                                </div>
                                <div className="span12 margintop10 form-group">
                                    <textarea className="form-control" name="message" rows="12" placeholder="Message" onChange={e => handleMessage(e.target.value)} value={message} style={{ resize: 'none' }} />
                                    <div className="validation" style={{ display: `${messageError === '' ? 'none' : 'block'}` }}>{messageError}</div>
                                </div>
                                <div className="span12 margintop10 form-group">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '120px' }}>
                                            <img src={captchaImage} alt="" className="w-100 h-100 fit-contain" />
                                        </div>
                                        <div style={{ paddingLeft: '10px' }}>
                                            <button className="btn btn-danger" onClick={e => refreshCaptcha()}>
                                                <i className="icon-refresh"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="span12 margintop10 form-group">
                                    <input type="number" className="form-control" name="enteredCaptchaValue" id="enteredCaptchaValue" placeholder="Captcha" onChange={e => handleEnteredCaptchaValue(e.target.value)} value={enteredCaptchaValue} />
                                    <div className="validation" style={{ display: `${enteredCaptchaValueError === '' ? 'none' : 'block'}` }}>{enteredCaptchaValueError}</div>
                                </div>
                                <p className="text-center">
                                    <input type="submit" className="btn btn-large btn-theme margintop10" onClick={e => handleSubmit()} />
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
}

export default ContactUs